import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    key: "index",
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: T("产品名称"),
    dataIndex: "material_name",
    key: "material_name",
    ellipsis: true,
  },
  {
    title: T("产品编号"),
    dataIndex: "material_number",
    key: "material_number",
  },
  {
    title: T("批次"),
    key: "batch",
    scopedSlots: { customRender: "batch" },
    width: 240,
  },
  {
    title: T("数量"),
    key: "total_quantity",
    scopedSlots: { customRender: "total_quantity" },
  },
  {
    title: T("单位"),
    dataIndex: "unit",
    key: "unit",
  },
  {
    title: T("总重量(吨)"),
    dataIndex: "weight",
    key: "weight",
    scopedSlots: { customRender: "weight" },
  },
  {
    title: T("总体积(立方)"),
    dataIndex: "volume",
    key: "volume",
    scopedSlots: { customRender: "volume" },
  },
  {
    title: T("规格"),
    dataIndex: "material_spec",
    key: "material_spec",
  },
  {
    title: T("操作"),
    dataIndex: "action",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];
